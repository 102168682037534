
import { Driver } from "@/types";
import { defineComponent } from "vue";
import { auth, db } from "@/main";
import {
  collection,
  getDocs,
  updateDoc,
  addDoc,
  doc,
} from "firebase/firestore";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    edit_mode: {
      type: Boolean,
    },

    driver: {
      type: Object as () => Driver | null,
    },

    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      driver_name: "",
      error_msg: "",
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    back() {
      this.$props.close();
    },
    async updateDriver() {
      if (!this.driver_name) {
        this.error_msg = "Please enter a driver's name";
        return;
      }
      if (!auth.currentUser || auth.currentUser.uid === null) {
        this.error_msg = "Please login to create a driver";
        return;
      }

      if (!this.$props.driver) {
        this.error_msg = "Error updating driver. No driver passed in to update";
        return;
      }

      try {
        const docRef = await updateDoc(
          doc(
            db,
            `users/${auth.currentUser.uid}/drivers/`,
            this.$props.driver.id
          ),
          {
            name: this.driver_name,
          }
        );

        //add to store
        this.store.dispatch("setNeedsRefreshDrivers", true);
        this.$props.close();
      } catch (e) {
        console.error("Error adding document: ", e);
        this.error_msg = "Error editing driver";
      }

      console.log("create driver");
    },
    async createDriver() {
      if (!this.driver_name) {
        this.error_msg = "Please enter a driver's name";
        return;
      }
      if (!auth.currentUser || auth.currentUser.uid === null) {
        this.error_msg = "Please login to create a driver";
        return;
      }
      try {
        const docRef = await addDoc(
          collection(db, `users/${auth.currentUser.uid}/drivers/`),
          {
            name: this.driver_name,
            deleted: false,
          }
        );

        //add to store
        this.store.dispatch("setNeedsRefreshDrivers", true);
        this.$props.close();
      } catch (e) {
        console.error("Error adding document: ", e);
        this.error_msg = "Error creating driver";
      }

      console.log("create driver");
    },
  },

  //watch for changes to props
  watch: {
    edit_mode: {
      immediate: true,
      handler(val) {
        if (val) {
          console.log("val", val);
          if (this.$props.driver) {
            this.driver_name = this.$props.driver.name;
          }
        }
      },
    },
  },

 

  
});
