
import { auth, db } from "@/main";
import {
  collection,
  getDocs,
  where,
  query,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { Driver, Trip } from "@/types";

import { defineComponent } from "vue";
import HeaderComponent from "./components/header-component.vue";
import "primeicons/primeicons.css";
import SidebarComponent from "./components/sidebar-component.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "App",
  components: {
    HeaderComponent,
    SidebarComponent,
  },
  methods: {
    hideSidebar() {
      //if screen is bigger than 1100px
      if (window.innerWidth > 1100) {
        this.store.commit("setSidebarVisible", true);
      } else {
        this.store.commit("setSidebarVisible", false);
      }
    },
    convertFirebaseTimestampToDate(timestamp: any) {
      if (!timestamp || !timestamp.toDate) {
        return null;
      }
      return timestamp.toDate();
    },
    async loadTripsForDriver(id: string): Promise<Trip[] | null> {
      console.log("load data");

      if (!auth.currentUser || !auth.currentUser.uid) {
        console.log("no user. cannot load trips");
        return null;
      }

      if (!id) {
        console.log("no id. cannot load trips");
        return null;
      }

      const tripsCollection = collection(
        db,
        `users/${auth.currentUser.uid}/drivers/${id}/trips`
      );
      const tripsQuery = query(tripsCollection, orderBy("trip_date", "desc"));

      return getDocs(tripsQuery).then((querySnapshot) => {
        let trips: Trip[] = [];
        querySnapshot.forEach((doc) => {
          let trip = doc.data() as Trip;
          trip.id = doc.id;
          trip.trip_date = this.convertFirebaseTimestampToDate(trip.trip_date);
          trip.delivery_date = this.convertFirebaseTimestampToDate(
            trip.delivery_date
          );
          trips.push(trip);
        });

        return trips;
      });
    },
    selectDriver(driver_id?: string) {
      if (!driver_id) {
        console.log("no driver id to select");
        return;
      }
      console.log("select driver", driver_id);

      const driver = this.store.state.drivers?.find((d) => d.id === driver_id);
      if (driver && driver.trips) {
        this.store.dispatch("setSelectedDriver", driver);
      } else {
        console.log(`no trips for driver ${driver_id} found`);
      }
    },
    async getDrivers() {
      if (!auth.currentUser || !auth.currentUser.uid) {
        console.log("no user. cannot load drivers");
        return;
      }
      console.log("get drivers", auth);

      let drivers = [] as Driver[];
      const driversRef = collection(
        db,
        `users/${auth.currentUser.uid}/drivers`
      );
      const q = query(driversRef /* where("removed", "!=", true) */);

      await getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let driver = doc.data() as Driver;
          driver.id = doc.id;
          if (driver.removed !== true) {
            drivers.push(driver);
          }
        });
      });

      for (const driver of drivers) {
        driver.trips = await this.loadTripsForDriver(driver.id);
      }

      //save to store
      this.store.dispatch("setDrivers", drivers);
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    display_header() : any{
      return this.$route.path !== "/login";
    },
  },
  watch: {
    "store.state.needs_refresh_drivers": {
      handler(newValue) {
        if (newValue) {
          console.log("Refreshing drivers");

          this.getDrivers();
          this.store.commit("setNeedsRefreshDrivers", false);
        }
      },
    },
  },
  async mounted() {
    await this.getDrivers();

    if (
      this.store.state.drivers &&
      this.store.state.drivers.length > 0 &&
      !this.store.state.selected_driver
    ) {
      console.log(
        `mounted and selecting driver ${this.store.state.drivers[0].id}`
      );

      this.selectDriver(this.store.state.drivers[0].id);
    }
    this.hideSidebar();
    window.addEventListener("resize", this.hideSidebar);
   /*  //hide on route change
    this.$router.afterEach(() => {
      this.hideSidebar();
    }); */
  },
});
