
declare const google: any;
import { defineComponent, onMounted, ref } from "vue";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import { /*  doc, setDoc,  */ Timestamp } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from "@/main";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { useStore } from "vuex";
export default defineComponent({
  name: "TripCalculator",
  components: {
    Dropdown,
    Calendar,
    InputNumber,
    InputSwitch,
    VueGoogleAutocomplete,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      show_advanced_settings: false,
      step_number: 1,

      tripTypes: [
        "Dry Van",
        "Reefer",
        "Layover",
        "Cancel",
        "Detention",
        "Empty Miles",
        "Other",
      ],

      //Form
      company_name: null as string | null,
      trip_date: null as Date | null,
      delivery_date: null as Date | null,
      trip_type: null as string | null,
      start_location: null as string | null,
      pick_up_location: null as string | null,
      delivery_location: null as string | null,
      gross_income: 0 as number,
      notes: null as any,

      //calculated
      empty_miles: null as number | null,
      empty_miles_payment: null as number | null,
      loaded_miles: null as number | null,
      loaded_miles_payment: null as number | null,
      total_payment: 0 as number,
      net_income: null as number | null,

      //other
      driver_rate: null as number | null,
      empty_miles_rate: 0.3 as number,

      googleDistanceService: null as any,

      error_msg: "",
    };
  },

  methods: {
    setTestData() {
      this.company_name = "ABC Trucking";
      this.trip_date = new Date("2023-12-10");
      this.trip_type = "Reefer";
      this.start_location = "7909 S Union Ridge Pkwy, Ridgefield, WA, USA";
      this.pick_up_location = "655 West Reserve Drive, Kalispell, MT, USA";
      this.delivery_location = "9700 U.S. 290, Austin, TX, USA";
      this.gross_income = 3200;
    },

    getAddressData(addressData: any, placeResultData: any, id: any) {
      let address = `${addressData.street_number ?? ""} ${addressData.route ?? ""
        }, ${addressData.locality ?? ""}, ${addressData.administrative_area_level_1 ?? ""
        } ${addressData.postal_code ?? ""}`;

      console.log(id);

      if (id === "start_autocomplete") {
        this.start_location = address;
      } else if (id === "pick_up_autocomplete") {
        this.pick_up_location = address;
      } else if (id === "delivery_autocomplete") {
        this.delivery_location = address;
      }
    },
    async calculate() {
      if (!this.form_valid) {
        console.log("Form is not valid");
        this.error_msg =
          "Please make sure to fill out all of the required fields.";
        return;
      }
      if (
        this.trip_type == "Layover" ||
        this.trip_type == "Cancel" ||
        this.trip_type == "Detention" ||
        this.trip_type == "Other"
      ) {
        this.net_income = this.gross_income - this.total_payment;
        this.step_number = 2;
        return;
      }


      if (
        this.start_location &&
        this.pick_up_location &&
        this.googleDistanceService
      ) {

        const empty_miles_request = {
          origins: [this.start_location],
          destinations: [this.pick_up_location],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          avoidHighways: false,
          avoidTolls: true,
        };

        console.log(empty_miles_request);

        let response = await this.googleDistanceService.getDistanceMatrix(
          empty_miles_request
        );
        if (!response) {
          console.log("No response from DistanceMatrixService");
          return;
        }

        this.empty_miles = this.round(
          response.rows[0].elements[0].distance.value * 0.000621371192
        );

        if (this.trip_type != "Empty Miles") {
          let loaded_request = {
            origins: [this.pick_up_location],
            destinations: [this.delivery_location],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.IMPERIAL,
            avoidHighways: false,
            avoidTolls: true,
          };

          let loaded_response =
            await this.googleDistanceService.getDistanceMatrix(loaded_request);

          this.loaded_miles = this.round(
            loaded_response.rows[0].elements[0].distance.value * 0.000621371192
          );

          this.loaded_miles_payment = this.round(
            this.loaded_miles * this.computed_driver_rate
          );
        } else {
          this.loaded_miles_payment = 0;
        }

        this.empty_miles_payment = this.round(
          this.empty_miles * this.empty_miles_rate
        );

        this.total_payment = this.round(
          this.loaded_miles_payment + this.empty_miles_payment
        );

        this.net_income = this.gross_income - this.total_payment;

        this.step_number = 2;
      } else {
        console.log(
          "No start or pick up location, or DistanceMatrixService not initialized"
        );
      }
    },
    async addTrip() {
      if (!auth.currentUser) {
        console.log("No user signed in");
        return;
      }
      if (
        !this.store.state.selected_driver ||
        !this.store.state.selected_driver.id
      ) {
        this.error_msg = "Add or select a driver before you add any trips.";
        return;
      }
      this.error_msg = "";
      if (
        !this.trip_type &&
        !this.total_payment &&
        !this.gross_income &&
        !this.net_income
      ) {
        this.error_msg =
          "Please make sure to fill out all of the required fields.";
        return;
      }

      try {
        const docRef = await addDoc(
          collection(
            db,
            `users/${auth.currentUser.uid}/drivers/${this.store.state.selected_driver.id}/trips`
          ),
          {
            start_location: this.start_location,
            company_name: this.company_name,
            delivery_location: this.delivery_location,
            driver_rate: this.computed_driver_rate,
            empty_miles: this.empty_miles,
            empty_miles_payment: this.empty_miles_payment,
            empty_miles_rate: 0.3,
            gross_income: this.gross_income,
            loaded_miles: this.loaded_miles,
            loaded_miles_payment: this.loaded_miles_payment,
            net_income: this.net_income,
            payment_posted: false,
            pick_up_location: this.pick_up_location,
            total_payment: this.total_payment,
            trip_date: this.trip_date,
            delivery_date: this.delivery_date,
            trip_type: this.trip_type,
            additional_notes: this.notes,
          }
        );
        console.log(
          "Document written with ID: ",
          docRef.id + this.trip_date + this.delivery_date
        );
        this.resetData();
        this.store.dispatch("setTripCalculatorVisible", false);
      } catch (error) {
        console.log(error);
      }
    },
    round(num: number) {
      num = Math.round(num * 100) / 100;
      return num;
    },
    resetData() {
      this.company_name = null;
      this.trip_date = null;
      this.delivery_date = null;
      this.trip_type = null;
      this.start_location = null;
      this.pick_up_location = null;
      this.delivery_location = null;
      this.gross_income = 0;
      this.notes = null;
      this.empty_miles = null;
      this.empty_miles_payment = null;
      this.loaded_miles = null;
      this.loaded_miles_payment = null;
      this.total_payment = 0;
      this.net_income = null;
      this.driver_rate = null;
      this.empty_miles_rate = 0.3;
      this.step_number = 1;
    },

    formatToUSD(value?: number | null) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value ?? 0);
    },

    formatDate(date?: Date | null) {
      return new Intl.DateTimeFormat("en-US").format(date ?? new Date());
    },

    back() {
      if (this.step_number == 1) {
        this.store.dispatch("setTripCalculatorVisible", false);
      } else {
        this.step_number = 1;
      }
    },

    getStringFromInput(ref: any) {
      return ref.value;
    },
    loadGoogleMapsScript() {
      return new Promise<void>((resolve, reject) => {
        if (typeof google !== "undefined") {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyA3UkQCS7puXMtOLyPgoJ-Ac3X-XfX6FRA&libraries=places";
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      });
    },
  },
  mounted() {
    this.loadGoogleMapsScript().then(() => {
      this.googleDistanceService = new google.maps.DistanceMatrixService();
    });
  },

  computed: {
    computed_driver_rate: function (): number {
      if (this.driver_rate == null) {
        switch (this.trip_type) {
          case "Reefer":
            return 0.45;
          case "Dry Van":
            return 0.35;
          default:
            return 0;
        }
      } else {
        return this.driver_rate;
      }
    },
    low_miles_warning: function (): string {
      if (!this.empty_miles || !this.loaded_miles) {
        return "";
      }
      if (this.empty_miles + this.loaded_miles < 1000)
        return "Miles are below 1000";
      return "";
    },

    form_valid: function (): boolean {
      if (
        this.store.state.selected_driver == null ||
        this.store.state.selected_driver.id == null
      ) {
        return false;
      }
      if (!this.trip_type) {
        return false;
      }

      if (this.trip_type == "Dry Van" || this.trip_type == "Reefer") {
        if (
          this.trip_type &&
          this.trip_date &&
          this.company_name &&
          this.start_location &&
          this.pick_up_location
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        this.trip_type == "Layover" ||
        this.trip_type == "Cancel" ||
        this.trip_type == "Detention" ||
        this.trip_type == "Other"
      ) {
        if (
          this.trip_type &&
          this.trip_date &&
          this.company_name &&
          this.total_payment
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.trip_type == "Empty Miles") {
        if (
          this.trip_type &&
          this.trip_date &&
          this.company_name &&
          this.start_location &&
          this.pick_up_location
        ) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    },
  },
});
