
import { defineComponent, watch } from "vue";
import Dropdown from "primevue/dropdown";
import { Driver } from "@/types";
import { useStore } from "vuex";
export default defineComponent({
  name: "DriverSelectCard",
  components: {
    Dropdown,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    title: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },
    options: {
      type: Array || null || undefined,
    },
  },
  data() {
    return {
      selectedDriver: null as unknown,
    };
  },
  mounted() {
    // Moved logic to watcher
  },
  methods: {
    selectDriver(driver: any) {
      this.selectedDriver = driver;
      this.store.dispatch("setSelectedDriver", driver);
    },
  },
  watch: {
    options(newValue: Driver[]) {
      if (newValue && newValue.length > 0) {
        this.selectedDriver =
          newValue.find(
            (driver: Driver) =>
              this.store.state.selected_driver?.id === driver.id
          ) || newValue[0];
      }
    },
  },
});
