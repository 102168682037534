
import { Driver } from "@/types";
import { defineComponent } from "vue";
import { auth, db } from "@/main";
import {
  collection,
  getDocs,
  updateDoc,
  addDoc,
  doc,
} from "firebase/firestore";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    driver: {
      type: Object as () => Driver ,
      required: true
    },

    close: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      error_msg: "",
    };
  },
  methods: {
    back() {
      this.$props.close();
    },
    async deleteDriver() {
      if (!this.$props.driver.id) {
        this.error_msg = "Error deleting driver. No driver id";
        return;
      }
      if (!auth.currentUser || auth.currentUser.uid === null) {
        this.error_msg = "Please login to create a driver";
        return;
      }

      if (!this.$props.driver) {
        this.error_msg = "Error updating driver. No driver passed in to update";
        return;
      }

      try {
        const docRef = await updateDoc(
          doc(
            db,
            `users/${auth.currentUser.uid}/drivers/`,
            this.$props.driver.id
          ),
          {
            removed: true,
          }
        );

        //add to store
        this.store.dispatch("setNeedsRefreshDrivers", true);
        this.$props.close();
      } catch (e) {
        console.error("Error adding document: ", e);
        this.error_msg = "Error editing driver";
      }

      console.log("create driver");
    },
  
  },

 
});
