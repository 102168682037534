
import { Driver, Trip } from "@/types";
import { defineComponent } from "vue";
import { auth, db } from "@/main";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import deleteDriver from "@/components/delete-driver.vue";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import DriverSelectCard from "@/components/driver-select-card.vue";
import createDriver from "@/components/create-driver.vue";
import emptyDashboardStatCard from "@/components/empty-dashboard-stat-card.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "DriversView",
  components: {
    DataTable,
    Column,
    Dialog,
    emptyDashboardStatCard,
    DriverSelectCard,
    createDriver,
    deleteDriver,
    /* Button */
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      selected_driver: null as Driver | null,
      editDialogVisible: false,
      createDriverDialogVisible: false,
      deleteDriverDialogVisible: false,
      modal_style: {
        display: "flex",
        maxHeight: "95%",
        maxWidth: "800px",
        width: "90%",
        padding: "24px",
        flexDirection: "column",
        borderRadius: "26px",
        background: "var(--bg-main, #fff)",
        boxShadow: "0px 3px 5px 0px rgba(93, 103, 128, 0.14)",
      },
    };
  },
  methods: {
    openEditDialog(driver: Driver) {
      this.selected_driver = driver;
      this.editDialogVisible = true;
    },

    openCreateDriverDialog() {
      this.createDriverDialogVisible = true;
    },

    openDeleteDriverDialog(driver: Driver) {
      this.selected_driver = driver;
      this.deleteDriverDialogVisible = true;
    },
  },
  computed: {
    drivers(): Driver[] | null {
      return this.store.state.drivers;
    },

    showEditCreateDialog(): boolean {
      return ((this.selected_driver !== undefined && this.editDialogVisible) ||
        (this.selected_driver !== undefined &&
          this.createDriverDialogVisible)) as boolean;
    },
  },
});
